"use strict";

import "core-js/actual";

// ---------------- //
// Global variables //

let curLang;
const mQ56 = window.matchMedia("(max-width: 35em)");
const mQ75 = window.matchMedia("(max-width: 46.875em)");
const mQ110 = window.matchMedia("(max-width: 68.75em)");
const mQ144 = window.matchMedia("(max-width: 90em)");

// -------------- //
// Service worker //

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(new URL("../serviceWorker.js", import.meta.url))
    .then(function (registration) {
      // Registration was successful
      console.log(
        "ServiceWorker registration successful with scope: ",
        registration.scope
      );
    })
    .catch(function (err) {
      // registration failed :(
      console.log("ServiceWorker registration failed: ", err);
    });
}

// -------------------------- //
// Flexbox gap fix for Safari //

function checkFlexGap() {
  var flex = document.createElement("div");
  flex.style.display = "flex";
  flex.style.flexDirection = "column";
  flex.style.rowGap = "1px";

  flex.appendChild(document.createElement("div"));
  flex.appendChild(document.createElement("div"));

  document.body.appendChild(flex);
  var isSupported = flex.scrollHeight === 1;
  flex.parentNode.removeChild(flex);
  console.log(`Flexbox ${isSupported ? "is" : "is not"} supported.`);

  if (!isSupported) document.body.classList.add("no-flexbox-gap");
}
checkFlexGap();

// --------------------------- //
// Smooth scrolling animation //

const allLinks = document.querySelectorAll(".sm-link");
allLinks.forEach(function (link) {
  link.addEventListener("click", function (e) {
    e.preventDefault();
    const href = link.getAttribute("href");

    // Scroll back to top
    if (href === "strona-główna")
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

    // Scroll to contact
    if (href === "kontakt" || href === "transport") {
      const sectionEl = document.querySelector(`#${href}`);
      const yOffset = -42;
      const y =
        sectionEl?.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }

    // Scroll to about and services
    if (href === "o-serwisie" || href === "usługi") {
      const sectionEl = document.querySelector(`#${href}`);
      const yOffset = -25;
      const y =
        sectionEl?.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }

    // Scroll to other links
    if (
      href !== "strona-główna" &&
      href !== "kontakt" &&
      href !== "o-serwisie" &&
      href !== "usługi" &&
      href !== "transport"
    ) {
      const sectionEl = document.querySelector(`#${href}`);
      sectionEl?.scrollIntoView({ behavior: "smooth" });
    }

    if (
      (href === "galeria" && mQ75.matches) ||
      (href === "umów-serwis" && mQ75.matches) ||
      (href === "o-serwisie" && mQ75.matches) ||
      (href === "usługi" && mQ75.matches)
    ) {
      const sectionEl = document.querySelector(`#${href}`);
      const yOffset = -40;
      const y =
        sectionEl?.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  });
});

// ------- //
// Opening //

const openingEL = document.querySelector(".opening");
let openTime, isOpen;

const createOpeningFrame = function () {
  openingEL.insertAdjacentHTML(
    "beforeend",
    `<p class="opening__text-box"><strong class="opening__status"></strong><span class="opening__status--next"></span><strong class="opening__time"></strong></p>`
  );
};

const calcOpening = function () {
  const now = new Date();
  const curDay = now.getDay();
  const hourNow = now.getHours() + "." + now.getMinutes();
  const openNow = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9);
  const closeNow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    18
  );
  const midnightNow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0
  );
  const weekdays = [
    ["Sunday"],
    ["Monday", 9, 18],
    ["Tuesday", 9, 18],
    ["Wednesday", 9, 18],
    ["Thursday", 9, 18],
    ["Friday", 9, 18],
    ["Saturday"],
  ];
  const day = weekdays[curDay];
  isOpen =
    hourNow >= day[1] && hourNow < day[2]
      ? curLang === "EN"
        ? "Open,"
        : "Otwarte,"
      : curLang === "EN"
      ? "Closed,"
      : "Zamknięte,";
  const timeLeft = function () {
    let out;
    if (curDay >= 1 && curDay <= 4) {
      if (hourNow >= 18) {
        out = 54000000 - (Date.now() - Date.parse(closeNow));
      } else if (hourNow < 9) {
        out = Date.parse(openNow) - Date.now();
      } else if (hourNow >= 9 && hourNow < 18) {
        out = Date.parse(closeNow) - Date.now();
      }
    }

    if (curDay === 5) {
      if (hourNow >= 18) {
        out = 226800000 - (Date.now() - Date.parse(closeNow));
      } else if (hourNow < 9) {
        out = Date.parse(openNow) - Date.now();
      } else if (hourNow >= 9 && hourNow < 18) {
        out = Date.parse(closeNow) - Date.now();
      }
    }

    if (curDay === 6) {
      out = 205200000 - (Date.now() - Date.parse(midnightNow));
    }

    if (curDay === 0) {
      out = 118800000 - (Date.now() - Date.parse(midnightNow));
    }
    return out;
  };
  const daysLeft = String(Math.trunc(timeLeft() / 86400000)).padStart(2, 0);
  const hoursLeftVal = Math.trunc((timeLeft() - daysLeft * 86400000) / 3600000);
  const hoursLeft =
    hoursLeftVal < 24 ? String(hoursLeftVal).padStart(2, 0) : "00";
  const minutesLeftVal = Math.ceil(
    (timeLeft() - daysLeft * 86400000 - hoursLeft * 3600000) / 60000
  );
  const minutesLeft =
    minutesLeftVal < 60 ? String(minutesLeftVal).padStart(2, 0) : "00";
  openTime = `${daysLeft}d : ${
    minutesLeft === "00" ? String(hoursLeftVal + 1).padStart(2, 0) : hoursLeft
  }h : ${minutesLeft}m`;
};

const updateOpening = function () {
  const openingStatus = document.querySelector(".opening__status");
  const nextOpeningStatus = document.querySelector(".opening__status--next");
  const openingTime = document.querySelector(".opening__time");
  // Add red or green class to openingStatus
  if (isOpen === "Otwarte," || isOpen === "Open,") {
    if (!openingStatus.classList.contains("green"))
      openingStatus.classList.add("green");
    if (openingStatus.classList.contains("red"))
      openingStatus.classList.remove("red");
  } else if (isOpen === "Zamknięte," || isOpen === "Closed,") {
    if (!openingStatus.classList.contains("red"))
      openingStatus.classList.add("red");
    if (openingStatus.classList.contains("green"))
      openingStatus.classList.remove("green");
  }
  // Update text content
  openingStatus.textContent = `${isOpen}`;
  nextOpeningStatus.textContent = `${curLang === "EN" ? "to" : "do"} ${
    curLang === "EN"
      ? isOpen === "Open,"
        ? "close"
        : "open"
      : isOpen === "Otwarte,"
      ? "zamknięcia"
      : "otwarcia"
  } ${curLang === "EN" ? "left:" : "pozostało:"} `;
  openingTime.textContent = `${openTime}`;
};

const refreshOpening = function () {
  calcOpening();
  updateOpening();
};

const init = function () {
  createOpeningFrame();
  calcOpening();
  updateOpening();
  setInterval(refreshOpening, 1000);
};
init();

// --------- //
// Dark mode //

let dark;

const darkCheckbox = document.querySelector(".dark-label input");

// Main
const main = document.querySelector("main");

// About
const sAbout = document.querySelector(".section-about");

// Featured
const sFeatured = document.querySelector(".section-featured");

// Services
const sServices = document.querySelector(".section-services");

// Inspection
const sInspection = document.querySelector(".article-inspection");

// Gallery
const sGallery = document.querySelector(".section-gallery");
const gsliderBtns = document.querySelectorAll(".gslider__btn");

// Contact
const sContact = document.querySelector(".section-contact");

// Form
const sForm = document.querySelector(".form__content-wrapper");

darkCheckbox.addEventListener("change", function (e) {
  e.preventDefault();
  if (this.checked) {
    dark = true;
    // Main
    main.style.backgroundColor = "#d5d5d5";

    // About
    sAbout.style.backgroundColor = "#222";
    sAbout.style.color = "#e6e6e6";

    // Featured
    sFeatured.style.backgroundColor = "#d5d5d5";

    // Services
    sServices.style.backgroundColor = "#d5d5d5";

    // Inspection
    sInspection.style.backgroundColor = "#d5d5d5";

    // Gallery
    sGallery.style.backgroundColor = "#222";
    sGallery.style.color = "#e6e6e6";
    gsliderBtns.forEach((btn) => {
      btn.style.backgroundColor = "rgba(255, 255, 255, 0.7)";
      btn.style.color = "#1e1e1e";
    });

    // Contact
    sContact.style.backgroundColor = "#d5d5d5";

    // Form
    sForm.style.backgroundColor = "#d5d5d5";
  } else if (!this.checked) {
    dark = false;
    // Main
    main.style.backgroundColor = "#fff";

    // About
    sAbout.style.backgroundColor = "#fff";
    sAbout.style.color = "#1e1e1e";

    // Featured
    sFeatured.style.backgroundColor = "#fff";

    // Services
    sServices.style.backgroundColor = "#e4e4e4";

    // Inspection
    sInspection.style.backgroundColor = "#e4e4e4";

    // Gallery
    sGallery.style.backgroundColor = "#fff";
    sGallery.style.color = "#1e1e1e";
    gsliderBtns.forEach((btn) => {
      btn.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      btn.style.color = "#e4e4e4";
    });

    // Contact
    sContact.style.backgroundColor = "#e4e4e4";

    // Form
    sForm.style.backgroundColor = "#e4e4e4";
  }
});

// --------------- //
// Language change //

// Header
const headerContHead2 = document.querySelector(".hch2").textContent;
const headerContText2 = document.querySelector(".hct2").textContent;
const headerContHead3 = document.querySelector(".hch3").textContent;

const headerContHead2Arr = document.querySelectorAll(".hch2");
const headerContText2Arr = document.querySelectorAll(".hct2");
const headerContHead3Arr = document.querySelectorAll(".hch3");

// Navigation
const nav1 = document.querySelector(".nav1").textContent;
const nav2 = document.querySelector(".nav2").textContent;
const nav3 = document.querySelector(".nav3").textContent;
const nav4 = document.querySelector(".nav4").textContent;
const nav5 = document.querySelector(".nav5").textContent;
const nav6 = document.querySelector(".nav6").textContent;

// Supported brands
const supportedBrands = document.querySelector(".supported-brands").textContent;

const langCheckbox = document.querySelector(".lang-label input");

// Slider
const slide1Heading = document.querySelector(".s1h1").textContent;
const slide1BtnFull = document.querySelector(".s1bf").textContent;
const slide1BtnOutline = document.querySelector(".s1bo").textContent;

const slide2Heading = document.querySelector(".s2h1").textContent;
const slide2BtnFull = document.querySelector(".s2bf").textContent;
const slide2BtnOutline = document.querySelector(".s2bo").textContent;

const slide3Heading = document.querySelector(".s3h1").textContent;
const slide3BtnFull = document.querySelector(".s3bf").textContent;
const slide3BtnOutline = document.querySelector(".s3bo").textContent;

// Section About
const secAboutHeading = document.querySelector(
  ".section-about .heading-secondary"
).textContent;
const secAboutText = document.querySelector(".about__text-box p").innerHTML;

// Section Services
const secServicesHeading = document.querySelector(
  ".section-services .heading-secondary"
).textContent;
const l1 = document.querySelector(".l1").textContent;
const l2 = document.querySelector(".l2").textContent;
const l3 = document.querySelector(".l3").textContent;
const l4 = document.querySelector(".l4").textContent;
const l5 = document.querySelector(".l5").textContent;
const l6 = document.querySelector(".l6").textContent;
const l7 = document.querySelector(".l7").textContent;
const l8 = document.querySelector(".l8").textContent;
const l9 = document.querySelector(".l9").textContent;
const l10 = document.querySelector(".l10").textContent;
const l11 = document.querySelector(".l11").textContent;
const l12 = document.querySelector(".l12").textContent;
const l13 = document.querySelector(".l13").textContent;
const l14 = document.querySelector(".l14").textContent;

// Article Transport
const secTransportText = document.querySelector(
  ".transport__text-box p"
).innerHTML;

// Article Inspection
const secInspectionHeading = document.querySelector(
  ".article-inspection .heading-teritary"
).textContent;
const secInspectionText = document.querySelector(
  ".inspection__text-box p"
).innerHTML;

// Section Gallery
const secGalleryHeading = document.querySelector(
  ".section-gallery .heading-secondary"
).textContent;

// Section Contact
const secContactHeading = document.querySelector(
  ".section-contact .heading-secondary"
).textContent;
const secContactAdress = document.querySelector(".contact__adress").textContent;

// Section Form
const secFormHeading = document.querySelector(
  ".section-form .heading-secondary"
).textContent;
const f1 = document.querySelector(".f1").textContent;
const f1p = document.querySelector(".f1p").getAttribute("placeholder");
const f2 = document.querySelector(".f2").textContent;
const f2p = document.querySelector(".f2p").getAttribute("placeholder");
const f3 = document.querySelector(".f3").textContent;
const f3p = document.querySelector(".f3p").getAttribute("placeholder");
const f4 = document.querySelector(".f4").textContent;
const f4p = document.querySelector(".f4p").getAttribute("placeholder");
const fbtn = document.querySelector(".fbtn").textContent;

langCheckbox.addEventListener("change", function (e) {
  e.preventDefault();
  if (this.checked) {
    curLang = "EN";
    refreshOpening();
    // Header
    headerContHead2Arr.forEach((el) => (el.textContent = "Opening hours"));
    headerContText2Arr.forEach(
      (el) => (el.textContent = "Mo. - Fri. 9:00 - 18:00")
    );
    headerContHead3Arr.forEach((el) => (el.textContent = "Call now!"));

    // Navigation
    document.querySelector(".nav1").textContent = "Main page";
    document.querySelector(".nav2").textContent = "About";
    document.querySelector(".nav3").textContent = "Services";
    document.querySelector(".nav4").textContent = "Gallery";
    document.querySelector(".nav5").textContent = "Contact";
    document.querySelector(".nav6").textContent = "Set service";

    // Supported brands
    document.querySelector(".supported-brands").textContent =
      "Supported brands:";

    // Slider
    // Slide 1
    document.querySelector(".s1h1").textContent =
      "Reliable repair of motorcycles, scooters and quads at an affordable price";
    document.querySelector(".s1bf").innerHTML = "Make&nbsp;an appointment!";
    document.querySelector(
      ".s1bo"
    ).innerHTML = `Learn&nbsp;more about&nbsp;us&nbsp;&dArr;`;
    // Slide 2
    document.querySelector(
      ".s2h1"
    ).innerHTML = `From minor repairs to a&nbsp;complete overhaul of the engine`;
    document.querySelector(".s2bf").innerHTML =
      "See&nbsp;our services&nbsp;&dArr;";
    document.querySelector(".s2bo").innerHTML = `Transport&nbsp;& Inspection`;
    // Slide 3
    document.querySelector(
      ".s3h1"
    ).innerHTML = `Where is the service located? Can I&nbsp;see repair photos?`;
    document.querySelector(".s3bf").innerHTML =
      "Directions&nbsp;to find&nbsp;us";
    document.querySelector(
      ".s3bo"
    ).innerHTML = `Repair&nbsp;images gallery&nbsp;&dArr;`;

    // Section About
    document.querySelector(".section-about .heading-secondary").textContent =
      "About";
    document.querySelector(".about__text-box p").innerHTML =
      "I&nbsp;have been running &nbsp;motorcycle service since 1994. It is located in Przemyśl (Podkarpackie Province). I&nbsp;repair motorcycles, quads and scooters of almost all brands available on the market. If you have a problem with your motorcycle, it needs adjustment, pre-season inspection or a major repair, I&nbsp;invite you to my service. Visit my website, contact me by phone or e-mail. You can also contact me when you are planning to buy a&nbsp;motorcycle and want to check its condition before buying.";

    // Section Services
    document.querySelector(".section-services .heading-secondary").textContent =
      "Services";
    document.querySelector(".l1").textContent =
      "Valve adjustments, carburetor synchronization and adjustment";
    document.querySelector(".l2").textContent =
      "Replacing the timing belt and timing tensioners";
    document.querySelector(".l3").textContent =
      "Cleaning carburettors using an ultrasonic cleaner";
    document.querySelector(".l4").textContent =
      "Replacement of operating fluids:";
    document.querySelector(".l5").textContent = "Engine oil";
    document.querySelector(".l6").textContent = "Coolant";
    document.querySelector(".l7").textContent =
      "Transmission and differentials oil";
    document.querySelector(".l8").textContent = "Brake fluid";
    document.querySelector(".l9").textContent =
      "Fluid in the hydraulic clutch cylinders";
    document.querySelector(".l10").textContent =
      "Replacement of oil, fuel and air filters";
    document.querySelector(".l11").textContent =
      "Clutch replacement and clutch basket regeneration";
    document.querySelector(".l12").textContent =
      "Current repairs and overhauls of engines";
    document.querySelector(".l13").textContent = "Sealing fluid leaks";
    document.querySelector(".l14").textContent =
      "Replacing suspension bearings and steering head bearings";

    // Section Transport
    document.querySelector(".transport__text-box p").innerHTML =
      "I&nbsp;offer professional transport of a&nbsp;motorcycle, scooter or quad with a&nbsp;specially prepared trailer. I&nbsp;have 2&nbsp;ramps and a&nbsp;complete set for attaching two-wheelers and quads to the trailer.<br /><br />Each motorcycle is attached individually, depending on its construction, using 3&nbsp;or 4&nbsp;durable load straps, which guarantees very high stability during transport, regardless of the unevenness and bumpiness of the road.";

    // Section Inspection
    document.querySelector(
      ".article-inspection .heading-teritary"
    ).textContent = "Inspection";
    document.querySelector(".inspection__text-box p").innerHTML =
      "You can contact me when you are planning to buy a&nbsp;motorcycle and want to check its condition before buying. As part of the inspection, I&nbsp;will comprehensively check the technical condition of the motorcycle and inform you about current and potential faults. You will also find out what condition the engine is in, whether its operation does not raise suspicions of a&nbsp;fault or other objections. Also I&nbsp;will check the condition of the most important systems for safety, such as the braking system, drive system and suspension.";

    // Section Gallery
    document.querySelector(".section-gallery .heading-secondary").textContent =
      "Gallery";

    // Section Contact
    document.querySelector(".section-contact .heading-secondary").textContent =
      "Contact";
    document.querySelector(".contact__adress").textContent = "Adress";

    // Section Form
    document.querySelector(".section-form .heading-secondary").textContent =
      "Contact Form";
    document.querySelector(".f1").textContent = "Full Name";
    document
      .querySelector(".f1p")
      .setAttribute("placeholder", "Your full name");
    document.querySelector(".f2").textContent = "Phone Number";
    document
      .querySelector(".f2p")
      .setAttribute("placeholder", "Your phone number");
    document.querySelector(".f3").textContent = "Email Adress";
    document
      .querySelector(".f3p")
      .setAttribute("placeholder", "Your email adress");
    if (document.querySelector(".f4"))
      document.querySelector(".f4").textContent = "Message";
    if (document.querySelector(".f4p"))
      document
        .querySelector(".f4p")
        .setAttribute("placeholder", "Your message");
    if (document.querySelector(".fbtn"))
      document.querySelector(".fbtn").textContent = "Send";
    if (document.querySelector(".fbtn.fix"))
      document.querySelector(".fbtn.fix").textContent = "Fix issues";
    if (submitMessage) {
      submitMessage.innerHTML = `<div class="submit-message"><h3 class="heading-teritary">${
        curLang === "EN"
          ? "Thank you for sending an email!"
          : "Dziękuję za przesłanie formularza!"
      }</h3><p class="content-text">${
        curLang === "EN"
          ? "I will contact you as fast as it is possible :)"
          : "Skontaktuję się z Tobą najszybiej jak to możliwe"
      } <br />${
        curLang === "EN"
          ? "But if you prefer to contact with me faster, then call me just now!"
          : "Jeśli jednak wolisz skontaktować się szybciej, to zadzwoń już teraz!"
      }</p><a href="tel:+48604884358" class="zoom-link">
      <p class="content-text form__number">+48 604 884 358</p>
      </a></div>`;
    }
    if (document.querySelector(".form__error-heading"))
      document.querySelector(".form__error-heading").textContent =
        "Before sending please fix issues:";
    if (document.querySelector(".name-err"))
      document.querySelector(".name-err").innerHTML = `<img
      class="list-icon form__icon"
      src="checkmark-outline.svg"
      >  Please fill name input properly`;
    if (document.querySelector(".number-err"))
      document.querySelector(".number-err").innerHTML = `<img
      class="list-icon form__icon"
      src="checkmark-outline.svg"
      >  Please fill phone number input properly`;
    if (document.querySelector(".email-err"))
      document.querySelector(".email-err").innerHTML = `<img
      class="list-icon form__icon"
      src="checkmark-outline.svg"
      >  Please fill email input properly`;
  } else if (!this.checked) {
    curLang = "PL";
    refreshOpening();
    // Header
    headerContHead2Arr.forEach((el) => (el.textContent = headerContHead2));
    headerContText2Arr.forEach((el) => (el.textContent = headerContText2));
    headerContHead3Arr.forEach((el) => (el.textContent = headerContHead3));

    // Navigation
    document.querySelector(".nav1").textContent = nav1;
    document.querySelector(".nav2").textContent = nav2;
    document.querySelector(".nav3").textContent = nav3;
    document.querySelector(".nav4").textContent = nav4;
    document.querySelector(".nav5").textContent = nav5;
    document.querySelector(".nav6").textContent = nav6;

    // Supported brands
    document.querySelector(".supported-brands").textContent = supportedBrands;

    // Slider
    document.querySelector(".s1h1").textContent = slide1Heading;
    document.querySelector(".s1bf").textContent = slide1BtnFull;
    document.querySelector(".s1bo").innerHTML = slide1BtnOutline;

    document.querySelector(".s2h1").textContent = slide2Heading;
    document.querySelector(".s2bf").textContent = slide2BtnFull;
    document.querySelector(".s2bo").innerHTML = slide2BtnOutline;

    document.querySelector(".s3h1").textContent = slide3Heading;
    document.querySelector(".s3bf").textContent = slide3BtnFull;
    document.querySelector(".s3bo").innerHTML = slide3BtnOutline;

    // Section About
    document.querySelector(".section-about .heading-secondary").textContent =
      secAboutHeading;
    document.querySelector(".about__text-box p").innerHTML = secAboutText;

    // Section Services
    document.querySelector(".section-services .heading-secondary").textContent =
      secServicesHeading;
    document.querySelector(".l1").textContent = l1;
    document.querySelector(".l2").textContent = l2;
    document.querySelector(".l3").textContent = l3;
    document.querySelector(".l4").textContent = l4;
    document.querySelector(".l5").textContent = l5;
    document.querySelector(".l6").textContent = l6;
    document.querySelector(".l7").textContent = l7;
    document.querySelector(".l8").textContent = l8;
    document.querySelector(".l9").textContent = l9;
    document.querySelector(".l10").textContent = l10;
    document.querySelector(".l11").textContent = l11;
    document.querySelector(".l12").textContent = l12;
    document.querySelector(".l13").textContent = l13;
    document.querySelector(".l14").textContent = l14;

    // Section Transport
    document.querySelector(".transport__text-box p").innerHTML =
      secTransportText;

    // Section Inspection
    document.querySelector(
      ".article-inspection .heading-teritary"
    ).textContent = secInspectionHeading;
    document.querySelector(".inspection__text-box p").innerHTML =
      secInspectionText;

    // Section Gallery
    document.querySelector(".section-gallery .heading-secondary").textContent =
      secGalleryHeading;

    // Section Contact
    document.querySelector(".section-contact .heading-secondary").textContent =
      secContactHeading;
    document.querySelector(".contact__adress").textContent = secContactAdress;

    // Section Form
    document.querySelector(".section-form .heading-secondary").textContent =
      secFormHeading;
    document.querySelector(".f1").textContent = f1;
    document.querySelector(".f1p").setAttribute("placeholder", f1p);
    document.querySelector(".f2").textContent = f2;
    document.querySelector(".f2p").setAttribute("placeholder", f2p);
    document.querySelector(".f3").textContent = f3;
    document.querySelector(".f3p").setAttribute("placeholder", f3p);
    if (document.querySelector(".f4"))
      document.querySelector(".f4").textContent = f4;
    if (document.querySelector(".f4p"))
      document.querySelector(".f4p").setAttribute("placeholder", f4p);
    if (document.querySelector(".fbtn"))
      document.querySelector(".fbtn").textContent = fbtn;
    if (document.querySelector(".fbtn.fix"))
      document.querySelector(".fbtn.fix").textContent = "Popraw błędy";
    if (submitMessage) {
      submitMessage.innerHTML = `<div class="submit-message"><h3 class="heading-teritary">${
        curLang === "EN"
          ? "Thank you for sending an email!"
          : "Dziękuję za przesłanie formularza!"
      }</h3><p class="content-text">${
        curLang === "EN"
          ? "I will contact you as fast as it is possible :)"
          : "Skontaktuję się z Tobą najszybiej jak to możliwe"
      } <br />${
        curLang === "EN"
          ? "But if you prefer to contact with me faster, then call me just now!"
          : "Jeśli jednak wolisz skontaktować się szybciej, to zadzwoń już teraz!"
      }</p><a href="tel:+48604884358" class="zoom-link">
    <p class="content-text form__number">+48 604 884 358</p>
  </a></div>`;
    }
    if (document.querySelector(".form__error-heading"))
      document.querySelector(".form__error-heading").textContent =
        "Przed wysłaniem proszę poprawić błędy:";
    if (document.querySelector(".name-err"))
      document.querySelector(".name-err").innerHTML = `<img
      class="list__icon form__icon"
      src="checkmark-outline.svg"
      >  Wypełnij poprawnie pole z imieniem i nazwiskiem`;
    if (document.querySelector(".number-err"))
      document.querySelector(".number-err").innerHTML = `<img
      class="list__icon form__icon"
      src="checkmark-outline.svg"
      >  Wypełnij poprawnie pole z numerem telefonu`;
    if (document.querySelector(".email-err"))
      document.querySelector(".email-err").innerHTML = `<img
      class="list__icon form__icon"
      src="checkmark-outline.svg"
      >  Wypełnij poprawnie pole z emailem`;
  }
});

// ----------------- //
// Sticky navigation //

const sliderEL = document.querySelector(".section-hero");
const el = document.querySelector("html");
const navMarginD = `${-(
  window.getComputedStyle(el, null).getPropertyValue("font-size").slice(0, -2) *
  5.2
)}px`;
const navMarginM = `${-(
  window.getComputedStyle(el, null).getPropertyValue("font-size").slice(0, -2) *
  6.4
)}px`;
const navMargin = mQ75.matches ? navMarginM : navMarginD;
const observer = new IntersectionObserver(
  function (entries) {
    const [entry] = entries;
    if (entry.isIntersecting === false) {
      document.querySelector(".header").classList.add("sticky");
    }
    if (entry.isIntersecting) {
      document.querySelector(".header").classList.remove("sticky");
    }
  },
  {
    root: null,
    threshold: 0,
    rootMargin: navMargin,
  }
);
observer.observe(sliderEL);

// ---------------- //
// Incoming content //

const incomingSections = document.querySelectorAll(".incoming");
let rootMarginResp = `${-0.1875 * window.innerHeight}px`;

const incomingAboutObserver = new IntersectionObserver(
  function (entries) {
    const [entry] = entries;
    if (entry.isIntersecting) {
      entry.target.childNodes[1].style.transform = "translateY(0)";
      incomingAboutObserver.unobserve(entry.target);
    }
  },
  {
    root: null,
    threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    rootMargin: rootMarginResp,
  }
);

incomingSections.forEach((section) => {
  section.childNodes[1].style.transform = "translateY(40%)";
  incomingAboutObserver.observe(section);
});

if (window.innerHeight / window.innerWidth >= 1.8) {
  document.getElementById("usługi").childNodes[1].style.transform =
    "translateY(0)";
}

// ------ //
// Slider //

const slider = function () {
  const slides = document.querySelectorAll(".slide");
  const btnLeft = document.querySelector(".slider__btn--left");
  const btnRight = document.querySelector(".slider__btn--right");
  const dotContainer = document.querySelector(".dots");
  let curSlide = 0;

  // Functions //
  // --------- //

  const createDots = function () {
    slides.forEach((_, i) => {
      dotContainer.insertAdjacentHTML(
        "beforeend",
        `<button class="dots__dot u-no-offset" aria-label="Slider dot number ${i}" data-slide= "${i}" ></button>`
      );
    });
  };

  const activateDot = function (slide) {
    document
      .querySelectorAll(".dots__dot")
      .forEach((dot) => dot.classList.remove("dots__dot--active"));

    document
      .querySelector(`.dots__dot[data-slide="${slide}"]`)
      .classList.add("dots__dot--active");
  };

  const goToSlide = function (slide) {
    slides.forEach(
      (s, i) => (s.style.transform = `translateX(${(i - slide) * 100}%)`)
    );
    activateDot(slide);
    curSlide = +slide;
  };

  let timer;
  const autoSlide = function () {
    timer = setInterval(nextSlide, 8000);
    return timer;
  };

  const nextSlide = function () {
    if (curSlide === slides.length - 1) curSlide = 0;
    else curSlide++;
    goToSlide(curSlide);
    if (timer) clearInterval(timer);
    timer = autoSlide();
    // activateDot(curSlide);
  };

  const prevSlide = function () {
    if (curSlide === 0) curSlide = slides.length - 1;
    else curSlide--;
    goToSlide(curSlide);
    if (timer) clearInterval(timer);
    timer = autoSlide();
    // activateDot(curSlide);
  };

  const init = function () {
    createDots();
    activateDot(0);
    autoSlide();
  };
  init();

  // Next slide
  btnRight.addEventListener("click", nextSlide);
  // Previous slide
  btnLeft.addEventListener("click", prevSlide);

  // Arrow slide change
  document.addEventListener("keydown", function (e) {
    if (e.key === "ArrowRight") nextSlide();
    e.key === "ArrowLeft" && prevSlide();
  });

  dotContainer.addEventListener("click", function (e) {
    if (e.target.classList.contains("dots__dot")) {
      const { slide } = e.target.dataset;
      goToSlide(slide);
      if (timer) clearInterval(timer);
      timer = autoSlide();
    }
  });
};
slider();

// -------------- //
// Gallery Slider //

const gSlider = function () {
  const gSlides = document.querySelectorAll(".gslide");
  const gBtnLeft = document.querySelector(".gslider__btn--left");
  const gBtnRight = document.querySelector(".gslider__btn--right");
  const gDotContainer = document.querySelector(".gdots");
  const movWidth = () => {
    let out = 106.27;
    if (mQ110.matches) out = 112.742258;
    if (mQ75.matches) out = 116.59166;
    return out;
  };
  let curSlide = 1;
  if (mQ110.matches) {
    gSlides.forEach(
      (gs, i) => (gs.style.transform = `translateX(${(i - 1) * movWidth()}%)`)
    );
  }

  // --------- //
  // Functions //

  const creategDots = function () {
    gSlides.forEach((_, i) => {
      gDotContainer.insertAdjacentHTML(
        "beforeend",
        `<button class="gdots__dot u-no-offset" aria-label="Gallery slider dot number ${i}" data-gslide = "${i}"></button>`
      );
    });
  };

  const activategDot = function (gSlide) {
    document
      .querySelectorAll(".gdots__dot")
      .forEach((dot) => dot.classList.remove("gdots__dot--active"));

    document
      .querySelector(`.gdots__dot[data-gslide="${gSlide}"]`)
      .classList.add("gdots__dot--active");
  };

  const goTogSlide = function (gSlide) {
    gSlides.forEach(
      (gs, i) =>
        (gs.style.transform = `translateX(${(i - gSlide) * movWidth()}%)`)
    );
    activategDot(gSlide);
    curSlide = +gSlide;
  };

  let gtimer;
  const autogSlide = function () {
    gtimer = setInterval(nextgSlide, 10000);
    return gtimer;
  };

  const nextgSlide = function () {
    if (curSlide === gSlides.length - 1) curSlide = 0;
    else curSlide++;
    goTogSlide(curSlide);
    if (gtimer) clearInterval(gtimer);
    gtimer = autogSlide();
  };

  const prevgSlide = function () {
    if (curSlide === 0) curSlide = gSlides.length - 1;
    else curSlide--;
    goTogSlide(curSlide);
    if (gtimer) clearInterval(gtimer);
    gtimer = autogSlide();
  };

  const ginit = function () {
    creategDots();
    activategDot(1);
    autogSlide();
  };
  ginit();

  // Next slide
  gBtnRight.addEventListener("click", nextgSlide);
  // Previous slide
  gBtnLeft.addEventListener("click", prevgSlide);

  gDotContainer.addEventListener("click", function (e) {
    if (e.target.classList.contains("gdots__dot")) {
      const { gslide } = e.target.dataset;
      goTogSlide(gslide);
      if (gtimer) clearInterval(gtimer);
      gtimer = autogSlide();
    }
  });
};
gSlider();

// ------------- //
// Gallery modal //

const galleries = document.querySelectorAll(".gallery__content-wrapper");
const galleryModal = document.querySelector(".gallery__modal");
const galleryImgs = [...document.querySelectorAll(".gallery__img")];
let backNum;
let curImg;
let srcNum;
galleries.forEach((gall) =>
  gall.addEventListener("click", function (e) {
    e.preventDefault();
    if (e.target.classList.contains("gallery__img")) {
      galleryModal.insertAdjacentHTML(
        "beforeend",
        `<img src="${
          e.target.closest(".gallery__img").src
        }" class="modal__img"/>
        <img class="close-modal" src="/close-outline.svg"></img>
        <button class="gallery__btn gallery__btn--left u-no-offset">&lsaquo;</button>
          <button class="gallery__btn gallery__btn--right u-no-offset">&rsaquo;</button>`
      );
      backNum =
        String(
          document.querySelector(".modal__img").getAttribute("src")
        ).includes("s.") ||
        String(
          document.querySelector(".modal__img").getAttribute("src")
        ).includes("b.")
          ? -16
          : -15;
      srcNum = String(
        document.querySelector(".modal__img").getAttribute("src")
      ).at(backNum);
      document.body.insertAdjacentHTML(
        "afterbegin",
        `<div class="blur-background"></div>`
      );
    }
  })
);

document.body.addEventListener(
  "click",
  function (e) {
    if (e.target === document.querySelector(".gallery__btn--left")) {
      backNum =
        String(
          document.querySelector(".modal__img").getAttribute("src")
        ).includes("s.") ||
        String(
          document.querySelector(".modal__img").getAttribute("src")
        ).includes("b.")
          ? -16
          : -15;

      srcNum = String(
        document.querySelector(".modal__img").getAttribute("src")
      ).at(backNum);

      curImg = galleryImgs.find(
        (img) => img.src === document.querySelector(".modal__img").src
      );
      const prevHrefSrc =
        srcNum > 1
          ? curImg.previousElementSibling.src
          : curImg.closest(".gallery__img-box").lastElementChild.src;

      document.querySelector(".modal__img").setAttribute("src", prevHrefSrc);
    }

    if (e.target === document.querySelector(".gallery__btn--right")) {
      backNum =
        String(
          document.querySelector(".modal__img").getAttribute("src")
        ).includes("s.") ||
        String(
          document.querySelector(".modal__img").getAttribute("src")
        ).includes("b.")
          ? -16
          : -15;

      srcNum = String(
        document.querySelector(".modal__img").getAttribute("src")
      ).at(backNum);

      curImg = galleryImgs.find(
        (img) => img.src === document.querySelector(".modal__img").src
      );
      const nextHrefSrc =
        srcNum < 8
          ? curImg.nextElementSibling.src
          : curImg.closest(".gallery__img-box").firstElementChild.src;

      document.querySelector(".modal__img").setAttribute("src", nextHrefSrc);
    }

    if (
      e.target !== document.querySelector(".modal__img") &&
      e.target !== document.querySelector(".gallery__btn--left") &&
      e.target !== document.querySelector(".gallery__btn--right")
    ) {
      document.querySelector(".blur-background")?.remove();
      document.querySelector(".modal__img")?.remove();
      document.querySelector(".close-modal")?.remove();
      document.querySelector(".gallery__btn--left")?.remove();
      document.querySelector(".gallery__btn--right")?.remove();
    }
  },
  true
);

// ------ //
// Submit //

const formTextBox = document.querySelector(".form__text-box");
const formHeading = document.querySelector(
  ".form__text-box .heading-secondary"
);
const form = document.querySelector(".form");
const inputName = document.querySelector("input[name=full-name]");
const inputNumber = document.querySelector("input[name=phone-number]");
const inputEmail = document.querySelector("input[name=email]");
const formMessage = document.querySelector(".form__message");
const refreshForm = document.querySelector(".form__refresh");
const btnForm = document.querySelector(".btn--form");
const formHead = document.querySelector(".form__head");

let inputMessage = document.querySelector(".input__message");
let curMessage = "";
let submitMessage;

const fixError = function (e) {
  e.preventDefault();
  formMessage.innerHTML = `<label class="form__label f4" for="message">${
    curLang === "EN" ? "Message" : "Wiadomość"
  }</label>
  <textarea
    id="message"
    name="message"
    class="input input__message f4p"
    rows="10"
    placeholder=${
      curLang === "EN" ? "Your&nbsp;message" : "Twoja&nbsp;wiadomość"
    }
    required
  ></textarea>`;
  inputMessage = document.querySelector(".input__message");
  inputMessage.value = curMessage;
  formMessage.removeAttribute("style");
  btnForm.setAttribute("type", "submit");
  btnForm.textContent = curLang === "EN" ? "Send" : "Wyślij";
  btnForm.removeEventListener("click", fixError, true);
};

const submitHandler = (e) => {
  e.preventDefault();

  let formErrors = [];
  const nameErr = [
    curLang === "EN"
      ? "Please fill name input properly"
      : "Wypełnij poprawnie pole z imieniem i nazwiskiem",
    "name-err",
  ];
  const numberErr = [
    curLang === "EN"
      ? "Please fill phone number input properly"
      : "Wypełnij poprawnie pole z numerem telefonu",
    "number-err",
  ];
  const emailErr = [
    curLang === "EN"
      ? "Please fill email input properly"
      : "Wypełnij poprawnie pole z emailem",
    "email-err",
  ];

  // ---------- //
  // Validation //

  // Expression which tests full name
  const regName =
    /^[\sAaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż]+$/;

  const wordLengthCheck = function () {
    const inNameVal = inputName.value;
    const wordsArr = [...inNameVal.split(" ")];
    const resultArr = wordsArr.map((word) => {
      const cleanWord = word.trim();
      return cleanWord.length >= 2;
    });
    if (resultArr.includes(false)) return "error";
  };

  if (
    inputName.value.length <= 2 ||
    !regName.test(inputName.value) ||
    wordLengthCheck() === "error"
  ) {
    formErrors.push(nameErr);
  } else {
    if (formErrors.includes(nameErr))
      formErrors.splice(formErrors.indexOf(nameErr), 1);
  }

  // Expression which tests phone number
  const regNumber1 =
    /(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/;

  if (!regNumber1.test(inputNumber.value) && inputNumber.value !== "") {
    formErrors.push(numberErr);
  } else {
    if (formErrors.includes(numberErr))
      formErrors.splice(formErrors.indexOf(numberErr), 1);
  }

  // Expression which tests email adress
  const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (!regEmail.test(inputEmail.value)) {
    formErrors.push(emailErr);
  } else {
    if (formErrors.includes(emailErr))
      formErrors.splice(formErrors.indexOf(emailErr), 1);
  }

  if (!formErrors.length) {
    // No errors means, that we can send email
    formHeading.classList.add("opacity0");
    formHead.style.marginBottom = "0";
    form.classList.add("hide");

    formTextBox.insertAdjacentHTML(
      "beforeend",
      `<div class="submit-message"><h3 class="heading-teritary">${
        curLang === "EN"
          ? "Thank you for sending an email!"
          : "Dziękuję za przesłanie formularza!"
      }</h3><p class="content-text">${
        curLang === "EN"
          ? "I will contact you as fast as it is possible :)"
          : "Skontaktuję się z Tobą najszybiej jak to możliwe"
      } <br />${
        curLang === "EN"
          ? "But if you prefer to contact with me faster, then call me just now!"
          : "Jeśli jednak wolisz skontaktować się szybciej, to zadzwoń już teraz!"
      }</p><a href="tel:+48604884358" class="zoom-link">
    <p class="content-text form__number">+48 604 884 358</p>
  </a></div>`
    );
    submitMessage = document.querySelector(".submit-message");
    formTextBox.style.textAlign = "center";
    formTextBox.style.lineHeight = "1.6";
    formTextBox.style.justifyContent = "center";
    btnForm.classList.remove("fix");
    setTimeout(function () {
      form.submit();
    }, 8000);
  } else {
    // If we have errors
    curMessage = inputMessage.value ? inputMessage.value : "";
    formMessage.innerHTML = `
            <h3 class="content-heading form__error-heading">${
              curLang === "EN"
                ? "Before sending please fix issues:"
                : "Przed wysłaniem proszę poprawić błędy:"
            }</h3>
            <ul class="form__error-list">
                ${formErrors
                  .map(
                    (el) => `<li class="form__li ${el[1]}"><img
                    class="list__icon form__icon"
                    src="checkmark-outline.svg">  ${el[0]}</li>`
                  )
                  .join("")}
            </ul>
        `;
    formMessage.style.padding = "1.2rem 0";
    document
      .querySelector(".form__error-heading")
      .classList.add("u-low-heading-gap");
    btnForm.removeAttribute("type");
    btnForm.classList.add("fix");
    btnForm.textContent = curLang === "EN" ? "Fix issues" : "Popraw błędy";
    btnForm.addEventListener("click", fixError, true);
  }
};

addEventListener("submit", submitHandler);

refreshForm.addEventListener(
  "click",
  function (e) {
    e.preventDefault();
    inputName.value = "";
    inputNumber.value = "";
    inputEmail.value = "";
    formMessage.innerHTML = `<label class="form__label f4" for="message">${
      curLang === "EN" ? "Message" : "Wiadomość"
    }</label>
    <textarea
      id="message"
      name="message"
      class="input input__message f4p"
      rows="10"
      placeholder=${
        curLang === "EN" ? "Your&nbsp;message" : "Twoja&nbsp;wiadomość"
      }
      required
    ></textarea>`;
    inputMessage = document.querySelector(".input__message");
    formHeading.classList?.remove("opacity0");
    formHead.style.marginBottom = "8rem";
    if (mQ144.matches) formHead.style.marginBottom = "4rem";
    form.classList?.remove("hide");
    submitMessage?.remove();
    formTextBox.removeAttribute("style");
    formMessage.removeAttribute("style");
    btnForm.textContent = curLang === "EN" ? "Send" : "Wyślij";
    btnForm.classList.remove("fix");
    btnForm.setAttribute("type", "submit");
    btnForm.removeEventListener("click", fixError, true);
  },
  true
);

// --------------- //
// Hover link fade //
const mainNavLinks = document.querySelectorAll(".main-nav__link");

mainNavLinks.forEach((link) =>
  link.addEventListener("mouseenter", function (e) {
    mainNavLinks.forEach((link) => {
      if (link !== e.target) link.style.color = "#d5d5d5";
    });
  })
);

mainNavLinks.forEach((link) =>
  link.addEventListener("mouseleave", function (e) {
    mainNavLinks.forEach((link) => {
      link.style.color = "#fff";
    });
  })
);

// ----------- //
// Mobile menu //
const btnOpen = document.querySelector(".main-nav__menu");
const mainNavList = document.querySelector(".main-nav__list");
const mainNav = document.querySelector(".main-nav");
const mobileClicked = function (e) {
  if (
    e.target.classList.contains("main-nav__link") ||
    e.target.classList.contains("main-nav__home")
  ) {
    mainNavList.classList.toggle("u-open");
    btnOpen.classList.toggle("open");
    mainNav.removeEventListener("click", mobileClicked);
  }
};
const mobileMenu = function () {
  mainNavList.classList.toggle("u-open");
  btnOpen.classList.toggle("open");
  if (mainNavList.classList.contains("u-open"))
    mainNav.addEventListener("click", mobileClicked);
};

btnOpen.addEventListener("click", mobileMenu);

// --------------- //
// Queries changes //
const iFrame = document.querySelector(".iframe");
const baseFontSize = window.getComputedStyle(document.body).fontSize;
const baseSizePercent = parseFloat(
  baseFontSize.substring(0, baseFontSize.length - 2) / 16
);

rootMarginResp = `${-0.09473 * window.innerWidth * baseSizePercent}px`;
iFrame.setAttribute(
  "width",
  `${document.querySelector(".iframe__box").clientWidth}`
);
iFrame.setAttribute(
  "height",
  `${document.querySelector(".iframe__box").clientHeight}`
);

window.addEventListener("resize", function (e) {
  e.preventDefault();
  rootMarginResp = `${-0.09473 * window.innerWidth * baseSizePercent}px`;
  iFrame.setAttribute(
    "width",
    `${document.querySelector(".iframe__box").clientWidth}`
  );
  iFrame.setAttribute(
    "height",
    `${document.querySelector(".iframe__box").clientHeight}`
  );
});
